import Headroom from "headroom.js";
import SmoothScroll from "smooth-scroll";
require('fslightbox');
import vhCheck from "vh-check";

import './components/toastify';

// import Toast from "bootstrap/js/src/toast";

window.Swal = require('sweetalert2');

import AOS from 'aos';

window.AOS = AOS;

/* HIDE HEADER ON SCROLL */
const header = document.getElementById('site-header');
let prevScrollPosition = scrollY;

window.addEventListener('scroll', function() {
    let currentScrollPosition = scrollY;

    if (scrollY > 0) {
        header.classList.add('smaller');
    } else {
        header.classList.remove('smaller');
    }
    if ((currentScrollPosition > 200) && (prevScrollPosition < currentScrollPosition)) {
        header.classList.add('hidden');
    } else {
        header.classList.remove('hidden');
    }
    prevScrollPosition = currentScrollPosition
});

/* OPEN MOBILE MENU */
const burgerBtn = document.querySelector('.open-mobile-menu');
const mobileMenu = document.querySelector('.mobile-menu');
const closeBtn = document.querySelector('.close');
const mobileOverlay = document.querySelector('.mobile-overlay');

burgerBtn.addEventListener('click', function() {
    mobileMenu.classList.add('open');
    mobileOverlay.classList.add('active');
});

closeBtn.addEventListener('click', function() {
    mobileMenu.classList.remove('open');
    mobileOverlay.classList.remove('active');
});

import "./components/slide-down";

